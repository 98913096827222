* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/*  Per vedere bordi
    *
    {
        outline: 1px solid red;
    }
*/

:root {
    --white: #fefcfdff;
    --space-cadet: #212243ff;
    --space-cadet-2: #212248ff;
    --mainOrange: #e32c24ff;
    --flame: var(--mediumOrange);


    --bodybackground: color-mix(in lab, var(--mainOrange), white 90%);
    --riconoscimenti: color-mix(in lab, var(--mainOrange), white 80%);

    --mainelements: color-mix(in lab, var(--mainOrange), white 25%);
    --mediumOrange: color-mix(in lab, var(--mainOrange), white 40%);
}

body {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: var(--bodybackground);
}

main {
    flex: 1;
    margin-top: 1vh;
    padding: 1vh;
}

#header {
    margin-top: 15vh;
}

h2,
h3,
h4,
h5,
h6,
.display-4 {
    color: var(--flame);
}

h5.footer {
    color: white;
}


/* Stili colori icon sidebar */
.social-icons li {
    margin-right: 15px;
}

.social-icons a {
    color: var(--flame);
    transition: color 0.3s ease;
}

.social-icons a:hover {
    color: #ff0000;
}

/* END FONTS */

hr.custom {
    height: 4px;
    border: none;
    background-color: var(--mediumOrange);
}

/*---- NAVBAR ----*/
nav.navbar.custom-navbar {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
    background: var(--mediumOrange) !important;
    padding: 15px 0 !important; /* Aumentato il padding per dare maggiore spessore */
}

.navbar-nav {
    justify-content: flex-start !important; /* Allinea il menu a sinistra */
}

.custom-navbar .nav-link {
    color: white !important; /* Colore del testo in bianco */
    font-size: 1.2rem !important; /* Dimensione del testo desktop */
}

/* Stili per schermi piccoli (mobile) */
@media (max-width: 767px) {
    nav.navbar.custom-navbar {
        padding: 20px 0 !important; /* Ulteriore aumento del padding per la versione mobile */
    }

    .custom-navbar .nav-link {
        font-size: 1.5rem !important; /* Aumenta la dimensione del testo per mobile */
    }

    /* Aumenta la dimensione del bottone toggle */
    .navbar-toggler {
        font-size: 1.5rem !important; /* Controlla la dimensione del bottone in versione mobile */
    }

    /* Aumenta la dimensione dell'icona del menu a tendina (hamburger menu) */
    .navbar-toggler-icon {
        width: 2.5em !important;  /* Ingrandisce la larghezza dell'icona */
        height: 2.5em !important; /* Ingrandisce l'altezza dell'icona */
        background-size: 2.5em 2.5em !important; /* Assicura che l'icona sia proporzionata */
    }
}


/* Stili generali per il header */
.header-with-margin {
    position: relative;
    text-align: center;
    margin-top: 0;
    padding: 0;
    padding-top: 56px;
}

.background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    z-index: -1;
}

.imgHeader {
    max-width: 80%;
    height: auto;
    margin: auto;
    position: relative;
    z-index: 1;
    margin-top: 10vh;
    transform: translateY(-40%);
    display: block;
}

@media (max-width: 480px) {
    .header-with-margin {
        height: 20vh;
        padding-top: 70px;
    }

    .background-image {
        height: 100%;
        background-size: 150% auto;
        background-position: top center;
    }

    .imgHeader {
        max-width: 50%;
        top: 50%;
        transform: translateY(-50%);
    }
}

/*---- ANIMATION CONTAINER ----*/
.animationContainer {
    height: 55vh;
}

/*----FOOTER ----*/
.custom-footer-bg {
    background-color: var(--mainelements);
}

.footer .list-inline-item a i {
    color: white;
}

/*---- CARD ----*/
.card .card-title {
    color: var(--mediumOrange);
}

.card {
    padding: 1%;
    justify-content: center;
}

/*---- CUSTOM BUTTONS ----*/
.btn {
    font-size: 1rem;
    padding: 0.375rem 0.75rem;
    background-color: var(--mediumOrange);
    border-color: var(--mediumOrange);
    color: #fff;
}

.btn:hover,
.btn:focus {
    background-color: #e32c24ff;
    border-color: #e32c24ff;
}

/*---- CUSTOMS ----*/
.main-with-margin {
    margin-top: 5vh;
}

.custom-box {
    padding: 2vh;
    margin-top: 2vh;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.custom-box-no-bg {
    padding: 2vh;
    margin-top: 2vh;
}


/*--- Sezione Contatti ---*/
ul {
    list-style-type: none;
}

.li {
    display: flex;
    flex-direction: row;
}

.list {
    margin-left: 1vw;
}

.contactImg {
    margin-left: 1vw;
    margin-top: -10px;
}

#popup {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border: 1px solid #ccc;
    z-index: 9999;
}

.section-bg,
.services .icon-box {
    background-color: #f8f9fa;
}

/*--------------------------------------------------------------
    Services
--------------------------------------------------------------*/

.serviceContainer {
    margin-top: 4%;
}

.services .icon-box {
    margin-bottom: 20px;
    padding: 30px;
    border-radius: 6px;
}

.services .icon-box i {
    float: left;
    color: #d9232d;
    font-size: 40px;
}

.services .icon-box h4 {
    margin-left: 70px;
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 18px;
}

.services .icon-box h4 a {
    color: #556270;
    transition: 0.3s;
}

.services .icon-box p {
    margin-left: 70px;
    line-height: 24px;
    font-size: 14px;
}

.services .icon-box:hover h4 a {
    color: #d9232d;
}

/*--------------------------------------------------------------
    Riconoscimeni e Affiliazioni
--------------------------------------------------------------*/
.clients {
    padding: 15px 0;
    background-color: var(--riconoscimenti);
    margin-top: 5%;
    margin-bottom: 2%;
}

.clients .row {
    display: flex;
    justify-content: center;
    /* Centra le immagini orizzontalmente */
}

.clients img {
    max-width: 45%;
    transition: all 0.4s ease-in-out;
    padding: 15px 0;
}


.first-img {
    transform: scale(2);
}


@media (max-width: 768px) {
    .clients img {
        max-width: 40%;
    }

    .clients .first-img {
        max-width: 30%;
    }
}


.sidebar-img {
    height: 25vh;
    object-fit: cover;
    background-color: #777;
}


/*--------------------------------------------------------------
    # Footer
--------------------------------------------------------------*/
#footer {
    background: var(--mainelements);
    padding: 0 0 30px 0;
    color: #fff;
    font-size: 14px;
}

#footer .footer-top {
    background: var(--mediumOrange);
    padding: 60px 0 30px 0;
}

#footer .footer-top .footer-info {
    margin-bottom: 30px;
}

#footer .footer-top .footer-info h3 {
    font-size: 24px;
    margin: 0 0 20px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    font-weight: 700;
}

#footer .footer-top .footer-info p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: "Raleway", sans-serif;
    color: #fff;
}

#footer .footer-top .social-links a {
    font-size: 18px;
    display: inline-block;
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 4px;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
    background: #d9232d;
    color: #fff;
    text-decoration: none;
}

#footer .footer-top h4 {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    position: relative;
    padding-bottom: 12px;
}

#footer .footer-top .footer-links {
    margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

#footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: rgba(255, 255, 255, 0.6);
    font-size: 18px;
    line-height: 1;
}

#footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
}

#footer .footer-top .footer-links ul a {
    color: rgba(255, 255, 255, 0.6);
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
    color: white;
}

#footer .footer-top .footer-newsletter form {
    margin-top: 30px;
    background: #fff;
    padding: 6px 10px;
    position: relative;
    border-radius: 4px;
}

#footer .footer-top .footer-newsletter form input[type=email] {
    border: 0;
    padding: 4px;
    width: calc(100% - 110px);
}

#footer .footer-top .footer-newsletter form input[type=submit] {
    position: absolute;
    top: 0;
    right: -2px;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 20px 2px 20px;
    background: #d9232d;
    color: #fff;
    transition: 0.3s;
    border-radius: 0 4px 4px 0;
}

#footer .footer-top .footer-newsletter form input[type=submit]:hover {
    background: #df3740;
}

#footer .copyright {
    text-align: center;
    padding-top: 30px;
}

#footer .credits {
    padding-top: 10px;
    text-align: center;
    font-size: 13px;
    color: rgba(255, 255, 255, 0.6);
}

#footer .credits a {
    color: rgba(255, 255, 255, 0.6);
    transition: 0.3s;
    font-weight: 600;
}

#footer .credits a:hover {
    color: white;
}



#footerFoldered {
    background: var(--mainelements);
    padding: 0 0 30px 0;
    color: #fff;
    font-size: 14px;
}

#footerFoldered .footer-top {
    background: var(--mediumOrange);
    padding: 60px 0 30px 0;
}

#footerFoldered .footer-top .footer-info {
    margin-bottom: 30px;
}

#footerFoldered .footer-top .footer-info h3 {
    font-size: 24px;
    margin: 0 0 20px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    font-weight: 700;
}

#footerFoldered .footer-top .footer-info p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: "Raleway", sans-serif;
    color: #fff;
}

#footerFoldered .footer-top .social-links a {
    font-size: 18px;
    display: inline-block;
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 4px;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
}

#footerFoldered .footer-top .social-links a:hover {
    background: #d9232d;
    color: #fff;
    text-decoration: none;
}

#footerFoldered .footer-top h4 {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    position: relative;
    padding-bottom: 12px;
}

#footerFoldered .footer-top .footer-links {
    margin-bottom: 30px;
}

#footerFoldered .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

#footerFoldered .footer-top .footer-links ul i {
    padding-right: 2px;
    color: rgba(255, 255, 255, 0.6);
    font-size: 18px;
    line-height: 1;
}

#footerFoldered .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
}

#footerFoldered .footer-top .footer-links ul li:first-child {
    padding-top: 0;
}

#footerFoldered .footer-top .footer-links ul a {
    color: rgba(255, 255, 255, 0.6);
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
}

#footerFoldered .footer-top .footer-links ul a:hover {
    color: white;
}

#footerFoldered .footer-top .footer-newsletter form {
    margin-top: 30px;
    background: #fff;
    padding: 6px 10px;
    position: relative;
    border-radius: 4px;
}

#footerFoldered .footer-top .footer-newsletter form input[type=email] {
    border: 0;
    padding: 4px;
    width: calc(100% - 110px);
}

#footerFoldered .footer-top .footer-newsletter form input[type=submit] {
    position: absolute;
    top: 0;
    right: -2px;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 20px 2px 20px;
    background: #d9232d;
    color: #fff;
    transition: 0.3s;
    border-radius: 0 4px 4px 0;
}

#footerFoldered .footer-top .footer-newsletter form input[type=submit]:hover {
    background: #df3740;
}

#footerFoldered .copyright {
    text-align: center;
    padding-top: 30px;
}

#footerFoldered .credits {
    padding-top: 10px;
    text-align: center;
    font-size: 13px;
    color: rgba(255, 255, 255, 0.6);
}

#footerFoldered .credits a {
    color: rgba(255, 255, 255, 0.6);
    transition: 0.3s;
    font-weight: 600;
}

#footerFoldered .credits a:hover {
    color: white;
}



/*--------------------------------------------------------------
    # Sections General
--------------------------------------------------------------*/
section {
    padding: 20px 0;
}

.section-bg {
    background-color: #f6f9fd;
}

.section-title {
    padding-bottom: 40px;
}

.section-title h2 {
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    line-height: 1px;
    margin: 0 0 5px 0;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #aaaaaa;
    font-family: "Poppins", sans-serif;
}

.section-title h2::after {
    content: "";
    width: 120px;
    height: 1px;
    display: inline-block;
    background: #f38b74;
    margin: 4px 10px;
}

.section-title p {
    margin: 0;
    margin: 0;
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
    color: #0f2f57;
}


/*--------------------------------------------------------------
    # About
--------------------------------------------------------------*/
#about {
    margin-top: 2vh;
}

.about .content h3 {
    font-weight: 600;
    font-size: 26px;
}

.about .content ul {
    list-style: none;
    padding: 0;
}

.about .content ul li {
    padding-left: 28px;
    position: relative;
}

.about .content ul li+li {
    margin-top: 10px;
}

.about .content ul i {
    position: absolute;
    left: 0;
    top: 2px;
    font-size: 20px;
    color: #ed502e;
    line-height: 1;
}

.about .content p:last-child {
    margin-bottom: 0;
}

.about .content .btn-learn-more {
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 5px;
    transition: 0.3s;
    line-height: 1;
    color: #ed502e;
    animation-delay: 0.8s;
    margin-top: 6px;
    border: 2px solid #ed502e;
}

.about .content .btn-learn-more:hover {
    background: #ed502e;
    color: #fff;
    text-decoration: none;
}


/* BOTTONE CUSTOM */
.btn-learn-more {
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 5px;
    transition: 0.3s;
    line-height: 1;
    color: #ed502e;
    animation-delay: 0.8s;
    margin-top: 6px;
    border: 2px solid #ed502e;
}

.btn-learn-more:hover {
    background: #ed502e;
    color: #fff;
    text-decoration: none;
}



/*--------------------------------------------------------------
    # Blog
 --------------------------------------------------------------*/
.blog {
    padding: 20px 0 20px 0;
}

.blog .entry {
    padding: 30px;
    margin-bottom: 60px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    background-color: white;
}

.blog .entry .entry-img {
    max-height: 440px;
    height: 50vh;
    margin: -30px -30px 20px -30px;
    overflow: hidden;
}

.blog .entry .entry-title {
    font-size: 28px;
    font-weight: bold;
    padding: 0;
    margin: 0 0 20px 0;
}

.blog .entry .entry-title a {
    color: #556270;
    transition: 0.3s;
}

.blog .entry .entry-title a:hover {
    color: #e32c24ff;
}

.blog .entry .entry-meta {
    margin-bottom: 15px;
    color: #c1c8d0;
}

.blog .entry .entry-meta ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    align-items: center;
    padding: 0;
    margin: 0;
}

.blog .entry .entry-meta ul li+li {
    padding-left: 20px;
}

.blog .entry .entry-meta i {
    font-size: 16px;
    margin-right: 8px;
    line-height: 0;
}

.blog .entry .entry-meta a {
    color: #777777;
    font-size: 14px;
    display: inline-block;
    line-height: 1;
}

.blog .entry .entry-content p {
    line-height: 24px;
}

.blog .entry .entry-content .read-more {
    -moz-text-align-last: right;
    text-align-last: right;
}

.blog .entry .entry-content .read-more a {
    display: inline-block;
    background: #e32c24ff;
    color: #fff;
    padding: 6px 20px;
    transition: 0.3s;
    font-size: 14px;
    border-radius: 4px;
}

.blog .entry .entry-content .read-more a:hover {
    background: #e32c24ff;
}

.blog .entry .entry-content h3 {
    font-size: 22px;
    margin-top: 30px;
    font-weight: bold;
}

.blog .entry .entry-content blockquote {
    overflow: hidden;
    background-color: #fafafa;
    padding: 60px;
    position: relative;
    text-align: center;
    margin: 20px 0;
}

.blog .entry .entry-content blockquote p {
    color: #444444;
    line-height: 1.6;
    margin-bottom: 0;
    font-style: italic;
    font-weight: 500;
    font-size: 22px;
}

.blog .entry .entry-content blockquote::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 3px;
    background-color: #556270;
    margin-top: 20px;
    margin-bottom: 20px;
}

.blog .entry .entry-footer {
    padding-top: 10px;
    border-top: 1px solid #e6e6e6;
}

.blog .entry .entry-footer i {
    color: #a4afba;
    display: inline;
}

.blog .entry .entry-footer a {
    color: #606f7e;
    transition: 0.3s;
}

.blog .entry .entry-footer a:hover {
    color: #e32c24ff;
}

.blog .entry .entry-footer .cats {
    list-style: none;
    display: inline;
    padding: 0 20px 0 0;
    font-size: 14px;
}

.blog .entry .entry-footer .cats li {
    display: inline-block;
}

.blog .entry .entry-footer .tags {
    list-style: none;
    display: inline;
    padding: 0;
    font-size: 14px;
}

.blog .entry .entry-footer .tags li {
    display: inline-block;
}

.blog .entry .entry-footer .tags li+li::before {
    padding-right: 6px;
    color: #6c757d;
    content: ",";
}

.blog .entry .entry-footer .share {
    font-size: 16px;
}

.blog .entry .entry-footer .share i {
    padding-left: 5px;
}

.blog .entry-single {
    margin-bottom: 30px;
}

.blog .blog-author {
    padding: 20px;
    margin-bottom: 30px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .blog-author img {
    width: 120px;
    margin-right: 20px;
}

.blog .blog-author h4 {
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 0px;
    padding: 0;
    color: #556270;
}

.blog .blog-author .social-links {
    margin: 0 10px 10px 0;
}

.blog .blog-author .social-links a {
    color: rgba(85, 98, 112, 0.5);
    margin-right: 5px;
}

.blog .blog-author p {
    font-style: italic;
    color: #b7b7b7;
}

.blog .blog-comments {
    margin-bottom: 30px;
}

.blog .blog-comments .comments-count {
    font-weight: bold;
}

.blog .blog-comments .comment {
    margin-top: 30px;
    position: relative;
}

.blog .blog-comments .comment .comment-img {
    margin-right: 14px;
}

.blog .blog-comments .comment .comment-img img {
    width: 60px;
}

.blog .blog-comments .comment h5 {
    font-size: 16px;
    margin-bottom: 2px;
}

.blog .blog-comments .comment h5 a {
    font-weight: bold;
    color: #444444;
    transition: 0.3s;
}

.blog .blog-comments .comment h5 a:hover {
    color: #d9232d;
}

.blog .blog-comments .comment h5 .reply {
    padding-left: 10px;
    color: #556270;
}

.blog .blog-comments .comment h5 .reply i {
    font-size: 20px;
}

.blog .blog-comments .comment time {
    display: block;
    font-size: 14px;
    color: #6b7b8d;
    margin-bottom: 5px;
}

.blog .blog-comments .comment.comment-reply {
    padding-left: 40px;
}

.blog .blog-comments .reply-form {
    margin-top: 30px;
    padding: 30px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .blog-comments .reply-form h4 {
    font-weight: bold;
    font-size: 22px;
}

.blog .blog-comments .reply-form p {
    font-size: 14px;
}

.blog .blog-comments .reply-form input {
    border-radius: 4px;
    padding: 10px 10px;
    font-size: 14px;
}

.blog .blog-comments .reply-form input:focus {
    box-shadow: none;
    border-color: #e9797f;
}

.blog .blog-comments .reply-form textarea {
    border-radius: 4px;
    padding: 10px 10px;
    font-size: 14px;
}

.blog .blog-comments .reply-form textarea:focus {
    box-shadow: none;
    border-color: #e9797f;
}

.blog .blog-comments .reply-form .form-group {
    margin-bottom: 25px;
}

.blog .blog-comments .reply-form .btn-primary {
    border-radius: 4px;
    padding: 10px 20px;
    border: 0;
    background-color: #556270;
}

.blog .blog-comments .reply-form .btn-primary:hover {
    background-color: #606f7e;
}

.blog .blog-pagination {
    color: #8795a4;
}

.blog .blog-pagination ul {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
}

.blog .blog-pagination li {
    margin: 0 5px;
    transition: 0.3s;
}

.blog .blog-pagination li a {
    color: #556270;
    padding: 7px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.blog .blog-pagination li.active,
.blog .blog-pagination li:hover {
    background: #d9232d;
}

.blog .blog-pagination li.active a,
.blog .blog-pagination li:hover a {
    color: #fff;
}

.blog .sidebar {
    padding: 30px;
    margin: 0 0 60px 20px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    background-color: white;
}

.blog .sidebar .sidebar-title {
    font-size: 20px;
    font-weight: 700;
    padding: 0 0 0 0;
    margin: 0 0 15px 0;
    color: #556270;
    position: relative;
}

.blog .sidebar .sidebar-item {
    margin-bottom: 30px;
}

.blog .sidebar .search-form form {
    background: #fff;
    border: 1px solid #ddd;
    padding: 3px 10px;
    position: relative;
}

.blog .sidebar .search-form form input[type=text] {
    border: 0;
    padding: 4px;
    border-radius: 4px;
    width: calc(100% - 40px);
}

.blog .sidebar .search-form form button {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 15px;
    margin: -1px;
    background: #d9232d;
    color: #fff;
    transition: 0.3s;
    border-radius: 0 4px 4px 0;
    line-height: 0;
}

.blog .sidebar .search-form form button i {
    line-height: 0;
}

.blog .sidebar .search-form form button:hover {
    background: #de323c;
}

.blog .sidebar .categories ul {
    list-style: none;
    padding: 0;
}

.blog .sidebar .categories ul li+li {
    padding-top: 10px;
}

.blog .sidebar .categories ul a {
    color: #556270;
    transition: 0.3s;
}

.blog .sidebar .categories ul a:hover {
    color: #d9232d;
}

.blog .sidebar .categories ul a span {
    padding-left: 5px;
    color: #aaaaaa;
    font-size: 14px;
}

.blog .sidebar .recent-posts .post-item+.post-item {
    margin-top: 15px;
}

.blog .sidebar .recent-posts img {
    width: 80px;
    float: left;
}

.blog .sidebar .recent-posts h4 {
    font-size: 15px;
    margin-left: 95px;
    font-weight: bold;
}

.blog .sidebar .recent-posts h4 a {
    color: #556270;
    transition: 0.3s;
}

.blog .sidebar .recent-posts h4 a:hover {
    color: #d9232d;
}

.blog .sidebar .recent-posts time {
    display: block;
    margin-left: 95px;
    font-style: italic;
    font-size: 14px;
    color: #aaaaaa;
}

.blog .sidebar .tags {
    margin-bottom: -10px;
}

.blog .sidebar .tags ul {
    list-style: none;
    padding: 0;
}

.blog .sidebar .tags ul li {
    display: inline-block;
}

.blog .sidebar .tags ul a {
    color: #96a2af;
    font-size: 14px;
    padding: 6px 14px;
    margin: 0 6px 8px 0;
    border: 1px solid white;
    display: inline-block;
    transition: 0.3s;
}

.blog .sidebar .tags ul a:hover {
    color: #fff;
    border: 1px solid #d9232d;
    background: #d9232d;
}

.blog .sidebar .tags ul a span {
    padding-left: 5px;
    color: #fbfbfc;
    font-size: 14px;
}


/*--------------------------------------------------------------
    # Back to top button
--------------------------------------------------------------*/
.back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    left: 25px;
    bottom: 55px;
    z-index: 996;
    background: #d9232d;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    transition: all 0.4s;
}

.back-to-top i {
    font-size: 28px;
    color: #fff;
    line-height: 0;
}

.back-to-top:hover {
    background: #e1444d;
    color: #fff;
}

.back-to-top.active {
    visibility: visible;
    opacity: 1;
}

/*--------------------------------------------------------------
    # Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
    padding: 18px 0;
    background: #f8f9fa;
    min-height: 40px;
    margin-top: 80px;
}

.breadcrumbs h2 {
    font-size: 32px;
    font-weight: 300;
    margin: 0;
}

.breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
}

.breadcrumbs ol li+li {
    padding-left: 10px;
}

.breadcrumbs ol li+li::before {
    display: inline-block;
    padding-right: 10px;
    color: #6b7b8d;
    content: "/";
}

@media (max-width: 992px) {
    .breadcrumbs {
        margin-top: 58px;
    }

    .breadcrumbs .d-flex {
        display: block !important;
    }

    .breadcrumbs h2 {
        margin-bottom: 10px;
    }

    .breadcrumbs ol {
        display: block;
    }

    .breadcrumbs ol li {
        display: inline-block;
    }
}

/*--------------------------------------------------------------
    # Blog
--------------------------------------------------------------*/
.blog {
    padding: 40px 0 20px 0;
}

.blog .entry {
    padding: 30px;
    margin-bottom: 60px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .entry .entry-img {
    max-height: 440px;
    margin: -30px -30px 20px -30px;
    overflow: hidden;
}

.blog .entry .entry-title {
    font-size: 28px;
    font-weight: bold;
    padding: 0;
    margin: 0 0 20px 0;
}

.blog .entry .entry-title a {
    color: #556270;
    transition: 0.3s;
}

.blog .entry .entry-title a:hover {
    color: #d9232d;
}

.blog .entry .entry-meta {
    margin-bottom: 15px;
    color: #c1c8d0;
}

.blog .entry .entry-meta ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    align-items: center;
    padding: 0;
    margin: 0;
}

.blog .entry .entry-meta ul li+li {
    padding-left: 20px;
}

.blog .entry .entry-meta i {
    font-size: 16px;
    margin-right: 8px;
    line-height: 0;
}

.blog .entry .entry-meta a {
    color: #777777;
    font-size: 14px;
    display: inline-block;
    line-height: 1;
}

.blog .entry .entry-content p {
    line-height: 24px;
}

.blog .entry .entry-content .read-more {
    -moz-text-align-last: right;
    text-align-last: right;
}

.blog .entry .entry-content .read-more a {
    display: inline-block;
    background: #d9232d;
    color: #fff;
    padding: 6px 20px;
    transition: 0.3s;
    font-size: 14px;
    border-radius: 4px;
}

.blog .entry .entry-content .read-more a:hover {
    background: #df3740;
}

.blog .entry .entry-content h3 {
    font-size: 22px;
    margin-top: 30px;
    font-weight: bold;
}

.blog .entry .entry-content blockquote {
    overflow: hidden;
    background-color: #fafafa;
    padding: 60px;
    position: relative;
    text-align: center;
    margin: 20px 0;
}

.blog .entry .entry-content blockquote p {
    color: #444444;
    line-height: 1.6;
    margin-bottom: 0;
    font-style: italic;
    font-weight: 500;
    font-size: 22px;
}

.blog .entry .entry-content blockquote::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 3px;
    background-color: #556270;
    margin-top: 20px;
    margin-bottom: 20px;
}

.blog .entry .entry-footer {
    padding-top: 10px;
    border-top: 1px solid #e6e6e6;
}

.blog .entry .entry-footer i {
    color: #a4afba;
    display: inline;
}

.blog .entry .entry-footer a {
    color: #606f7e;
    transition: 0.3s;
}

.blog .entry .entry-footer a:hover {
    color: #d9232d;
}

.blog .entry .entry-footer .cats {
    list-style: none;
    display: inline;
    padding: 0 20px 0 0;
    font-size: 14px;
}

.blog .entry .entry-footer .cats li {
    display: inline-block;
}

.blog .entry .entry-footer .tags {
    list-style: none;
    display: inline;
    padding: 0;
    font-size: 14px;
}

.blog .entry .entry-footer .tags li {
    display: inline-block;
}

.blog .entry .entry-footer .tags li+li::before {
    padding-right: 6px;
    color: #6c757d;
    content: ",";
}

.blog .entry .entry-footer .share {
    font-size: 16px;
}

.blog .entry .entry-footer .share i {
    padding-left: 5px;
}

.blog .entry-single {
    margin-bottom: 30px;
}

.blog .blog-author {
    padding: 20px;
    margin-bottom: 30px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .blog-author img {
    width: 120px;
    margin-right: 20px;
}

.blog .blog-author h4 {
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 0px;
    padding: 0;
    color: #556270;
}

.blog .blog-author .social-links {
    margin: 0 10px 10px 0;
}

.blog .blog-author .social-links a {
    color: rgba(85, 98, 112, 0.5);
    margin-right: 5px;
}

.blog .blog-author p {
    font-style: italic;
    color: #b7b7b7;
}

.blog .blog-comments {
    margin-bottom: 30px;
}

.blog .blog-comments .comments-count {
    font-weight: bold;
}

.blog .blog-comments .comment {
    margin-top: 30px;
    position: relative;
}

.blog .blog-comments .comment .comment-img {
    margin-right: 14px;
}

.blog .blog-comments .comment .comment-img img {
    width: 60px;
}

.blog .blog-comments .comment h5 {
    font-size: 16px;
    margin-bottom: 2px;
}

.blog .blog-comments .comment h5 a {
    font-weight: bold;
    color: #444444;
    transition: 0.3s;
}

.blog .blog-comments .comment h5 a:hover {
    color: #d9232d;
}

.blog .blog-comments .comment h5 .reply {
    padding-left: 10px;
    color: #556270;
}

.blog .blog-comments .comment h5 .reply i {
    font-size: 20px;
}

.blog .blog-comments .comment time {
    display: block;
    font-size: 14px;
    color: #6b7b8d;
    margin-bottom: 5px;
}

.blog .blog-comments .comment.comment-reply {
    padding-left: 40px;
}

.blog .blog-comments .reply-form {
    margin-top: 30px;
    padding: 30px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .blog-comments .reply-form h4 {
    font-weight: bold;
    font-size: 22px;
}

.blog .blog-comments .reply-form p {
    font-size: 14px;
}

.blog .blog-comments .reply-form input {
    border-radius: 4px;
    padding: 10px 10px;
    font-size: 14px;
}

.blog .blog-comments .reply-form input:focus {
    box-shadow: none;
    border-color: #e9797f;
}

.blog .blog-comments .reply-form textarea {
    border-radius: 4px;
    padding: 10px 10px;
    font-size: 14px;
}

.blog .blog-comments .reply-form textarea:focus {
    box-shadow: none;
    border-color: #e9797f;
}

.blog .blog-comments .reply-form .form-group {
    margin-bottom: 25px;
}

.blog .blog-comments .reply-form .btn-primary {
    border-radius: 4px;
    padding: 10px 20px;
    border: 0;
    background-color: #556270;
}

.blog .blog-comments .reply-form .btn-primary:hover {
    background-color: #606f7e;
}

.blog .blog-pagination {
    color: #8795a4;
}

.blog .blog-pagination ul {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
}

.blog .blog-pagination li {
    margin: 0 5px;
    transition: 0.3s;
}

.blog .blog-pagination li a {
    color: #556270;
    padding: 7px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.blog .blog-pagination li.active,
.blog .blog-pagination li:hover {
    background: #d9232d;
}

.blog .blog-pagination li.active a,
.blog .blog-pagination li:hover a {
    color: #fff;
}

.blog .sidebar {
    padding: 30px;
    margin: 0 0 60px 20px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .sidebar .sidebar-title {
    font-size: 20px;
    font-weight: 700;
    padding: 0 0 0 0;
    margin: 0 0 15px 0;
    color: #556270;
    position: relative;
}

.blog .sidebar .sidebar-item {
    margin-bottom: 30px;
}

.blog .sidebar .search-form form {
    background: #fff;
    border: 1px solid #ddd;
    padding: 3px 10px;
    position: relative;
}

.blog .sidebar .search-form form input[type=text] {
    border: 0;
    padding: 4px;
    border-radius: 4px;
    width: calc(100% - 40px);
}

.blog .sidebar .search-form form button {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 15px;
    margin: -1px;
    background: #d9232d;
    color: #fff;
    transition: 0.3s;
    border-radius: 0 4px 4px 0;
    line-height: 0;
}

.blog .sidebar .search-form form button i {
    line-height: 0;
}

.blog .sidebar .search-form form button:hover {
    background: #de323c;
}

.blog .sidebar .categories ul {
    list-style: none;
    padding: 0;
}

.blog .sidebar .categories ul li+li {
    padding-top: 10px;
}

.blog .sidebar .categories ul a {
    color: #556270;
    transition: 0.3s;
}

.blog .sidebar .categories ul a:hover {
    color: #d9232d;
}

.blog .sidebar .categories ul a span {
    padding-left: 5px;
    color: #aaaaaa;
    font-size: 14px;
}

.blog .sidebar .recent-posts .post-item+.post-item {
    margin-top: 15px;
}

.blog .sidebar .recent-posts img {
    width: 80px;
    float: left;
}

.blog .sidebar .recent-posts h4 {
    font-size: 15px;
    margin-left: 95px;
    font-weight: bold;
}

.blog .sidebar .recent-posts h4 a {
    color: #556270;
    transition: 0.3s;
}

.blog .sidebar .recent-posts h4 a:hover {
    color: #d9232d;
}

.blog .sidebar .recent-posts time {
    display: block;
    margin-left: 95px;
    font-style: italic;
    font-size: 14px;
    color: #aaaaaa;
}

.blog .sidebar .tags {
    margin-bottom: -10px;
}

.blog .sidebar .tags ul {
    list-style: none;
    padding: 0;
}

.blog .sidebar .tags ul li {
    display: inline-block;
}

.blog .sidebar .tags ul a {
    color: #96a2af;
    font-size: 14px;
    padding: 6px 14px;
    margin: 0 6px 8px 0;
    border: 1px solid white;
    display: inline-block;
    transition: 0.3s;
}

.blog .sidebar .tags ul a:hover {
    color: #fff;
    border: 1px solid #d9232d;
    background: #d9232d;
}

.blog .sidebar .tags ul a span {
    padding-left: 5px;
    color: #fbfbfc;
    font-size: 14px;
}



/*immagini galleria*/
.galleryimg {
    max-height: 25vh;
    margin-top: 1vh;
}

.spacing {
    justify-content: center;
    margin: 10px;
}

.containerGallery {
    display: flex;
    flex-wrap: wrap;
}

.pers {
    margin-top: 6vh;
}


/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info {
    width: 100%;
}

.contact .info i {
    font-size: 20px;
    color: #556270;
    float: left;
    width: 44px;
    height: 44px;
    background: #edeff1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
}

.contact .info h4 {
    padding: 0 0 0 60px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;

}

.contact .info p {
    padding: 0 0 0 60px;
    margin-bottom: 0;
    font-size: 14px;
    color: #8795a4;
}

.contact .info .email,
.contact .info .phone {
    margin-top: 40px;
}

.contact .php-email-form {
    width: 100%;

}

.contact .php-email-form .form-group {
    padding-bottom: 8px;
}

.contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    font-weight: 600;
}

.contact .php-email-form .error-message br+br {
    margin-top: 25px;
}

.contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
}

.contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
}

.contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
    border-radius: 4px;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
    border-color: #d9232d;
}

.contact .php-email-form input {
    height: 44px;
}

.contact .php-email-form textarea {
    padding: 10px 12px;
}

.contact .php-email-form button[type=submit] {
    background: #d9232d;
    border: 0;
    padding: 10px 24px;
    color: #fff;
    transition: 0.4s;
    border-radius: 4px;
}

.contact .php-email-form button[type=submit]:hover {
    background: #e24d55;
}

@keyframes animate-loading {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


/* Carosello Chi Siamo */
.carouselItem {
    width: 100%;
    height: auto;
    justify-self: center;
}

.carouselContainer {
    margin-bottom: 50px;
}

.carouselImage {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
}

#mainChiSiamo {
    margin-top: -40px;
}

/* Stili per la versione smartphone */
@media (max-width: 767.98px) {
    .carouselContainer {
        margin-bottom: 30px;
    }

    .carouselImage {
        height: auto;
        object-fit: contain;
    }

    .col-lg-6 {
        padding: 0 15px;
    }

    p {
        font-size: 14px;
        line-height: 1.6;
    }
}


.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.loading-spinner {
    width: 50px;
    height: 50px;
    border: 5px solid rgba(0, 0, 0, 0.1);
    border-top: 5px solid var(--mainOrange);
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.photo-gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
}

.photo-item {
    width: calc(25% - 10px);
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
    /* Transizione per l'effetto di ingrandimento */
}

.photo-item img {
    width: 100%;
    /* Rende l'immagine adattabile al contenitore */
    height: auto;
    /* Mantiene le proporzioni dell'immagine */
    object-fit: cover;
    /* Adatta l'immagine all'interno del contenitore senza distorcere */
    display: block;
    /* Rimuove lo spazio sotto le immagini */
}

.photo-item:hover {
    transform: scale(1.05);
    /* Ingrandisce l'immagine al passaggio del mouse */
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    position: relative;
    max-width: 60vw;
    /* Ridotto dal 90% al 60% della larghezza della finestra */
    max-height: 60vh;
    /* Ridotto dal 90% al 60% dell'altezza della finestra */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    padding: 10px;
    /* Ridotto il padding per una dimensione più compatta */
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    /* Ridotto il box-shadow per un effetto più sottile */
}

.modal-img {
    max-width: 60%;
    max-height: 60%;
    border-radius: 10px;
    object-fit: contain;
}

.close-button,
.prev-button,
.next-button {
    position: absolute;
    top: 50%;
    font-size: 5rem;
    cursor: pointer;
    user-select: none;
    transform: translateY(-50%);
    color: var(--mainOrange);
}

.close-button {
    top: 10px;
    right: 10px;
    font-size: 4rem;
}

.prev-button {
    left: 10px;
}

.next-button {
    right: 10px;
}


.main-content {
    margin-left: 8%;
    margin-right: 8%;
    margin-bottom: 5%;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.pagination-btn {
    display: flex;
    align-items: center;
    padding: 10px 10px;
    margin: 0 10px;
    background-color: var(--mainOrange);
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.pagination-btn:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.pagination-btn .arrow {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    margin: 0 10px;
    transition: transform 0.3s;
}

.pagination-btn .arrow.left {
    transform: rotate(-135deg);
}

.pagination-btn .arrow.right {
    transform: rotate(45deg);
}

.pagination-btn:hover {
    background-color: var(--mediumOrange);
}

.pagination-info {
    font-size: 16px;
    color: #333;
}

.no-underline {
    text-decoration: none;
    border-bottom: 2px solid var(--mediumOrange);
    padding-bottom: 2px;
    color: inherit;
}

.no-underline:hover {
    border-bottom-color: var(--mediumOrange);
}


.imgSinergie {
    max-height: 90%;
}

.font-size-mobile {
    font-size: 30px; /* Dimensione del font per mobile */
}

.font-size-desktop {
    font-size: 16px; /* Dimensione del font per desktop */
}

.img-fluid, img, a{
    user-select: none;
}

.font-size-mobile {
    font-size: 18px; /* Usa una dimensione maggiore rispetto a quella desktop */
}

.font-size-desktop {
    font-size: 16px;
}